.card {
    background-color: #66ccff;
    border: 1px solid #ffffff;
    border-radius: 10px;

    .line {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        & > div:nth-child(1) {
            flex-basis: 75%;

            div {
                font-weight: 600;
                color: #000000;
            }
        }
        & > div:nth-child(2) {
            flex-basis: 10%;
            text-align: center;
            padding-top: 3px;
            font-weight: 900;
        }
        & > div:nth-child(3) {
            flex-basis: 15%;
        }

        .buff_name {
            display: inline-block;

            img {
                vertical-align: top;
            }

            .name {
                vertical-align: top;
                padding-right: 4px;
            }
        }
    }

    .selected_line {
        border: 2px solid red;
        border-radius: 5px;
    }

    p {
        text-align: center;
        font-weight: 600;
        font-size: 1.2rem;
    }

    :global {
        .ant-input-number-group-addon {
            max-width: 12rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
