.content {
    width: 21%;
    height: 283px;
    background-color: #FAAFBE;
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    right: 0;
    top: 0;

    .title {
        font-weight: 600;
        font-size: 1.2rem;
        padding-bottom: 10px;
    }

    .subtitle {
        font-size: .75rem;
    }

    .select {
        width: 100%;
        margin-bottom: 10px;
    }
}
