.card {
    width: 16%;
    margin-bottom: 10px;
    margin-right: 0.64rem;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #39c5bb;
    text-align: center;

    &:nth-child(6) {
        margin-right: 0;
    }

    p {
        text-align: center;
        font-weight: 700;
        font-size: 1.1rem;
    }

    .select {
        width: 90%;
        margin-top: 4px;
    }

    .ability {
        width: 90%;
        margin-top: 4px;
    }

    .btn {
        width: 90%;
        margin-top: 4px;
    }

    .input {
        margin-top: 10px;
    }
}

.input_levels {
    margin-top: 10px;
}
