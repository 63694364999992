.box {
    width: 78%;
    background-color: #FFE212;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;

    p {
        font-weight: 600;
        font-size: 1.2rem;
    }

    .checkbox {
        margin-top: 20px;
        span {
            font-size: 0.75rem;
            font-weight: 600;
        }
    }
}
