.content {
    background-color: #ffffff;
    height: 80px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid #eeeeee;
    padding-top: 10px;

    .box {
        width: 80rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .p {
            width: 50%;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }
}
