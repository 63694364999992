.app {
    width: 80rem;
    margin: 0 auto;
    padding: 20px 0 100px;

    .line {
        width: 100%;
        height: 290px;
        position: relative;
    }

    .team {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
    }

    .gird {
        display: grid;
        grid-auto-rows: 5px;
        grid-template-columns: repeat(auto-fill, calc(33.333333% - 5px));
        align-items: start;
        justify-content: space-between;
    }
}
